.product-card {
  /* width: 380px; */
  position: relative;
  box-shadow: 0 2px 7px #dfdfdf;
  margin: 30px auto;
  background: #fafafa;
  height: 350px;
}

.badge {
  position: absolute;
  left: 10px;
  top: 10px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  background: red;
  color: #fff;
  padding: 3px 10px;
}

.featured-badge {
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  background: rgb(0, 204, 255);
  color: #fff;
  padding: 1px 5px;
  border-radius: 5px;
}

.product-tumb {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 50px;
  background: #f0f0f0;
}

.product-tumb img {
  max-width: 100%;
  max-height: 100%;
}

.product-details {
  padding: 15px;
}

.product-catagory {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccc;
  margin-bottom: 5px;
}

.product-details h4 a {
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #363636;
  text-decoration: none;
  transition: 0.3s;
}

.product-details h4 a:hover {
  color: rgb(0, 204, 255);
}

.product-details p {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 5px;
  color: #999;
}

.product-bottom-details {
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.product-bottom-details div {
  float: left;
  width: 50%;
}

.product-price {
  font-size: 18px;
  color: rgb(0, 165, 206);
  font-weight: 600;
}

.product-price small {
  font-size: 80%;
  font-weight: 400;
  text-decoration: line-through;
  display: inline-block;
  margin-right: 5px;
}

.product-links {
  text-align: right;
}

.product-links a {
  display: inline-block;
  margin-left: 5px;
  color: #e1e1e1;
  transition: 0.3s;
  font-size: 17px;
}

.product-links a:hover {
  color: #fbb72c;
}

/* ==========================================Product Detail==================================== */

.pd-wrap {
  padding: 40px 0;
  font-family: "Poppins", sans-serif;
}

.heading-section {
  text-align: center;
  margin-bottom: 20px;
}

.sub-heading {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  display: block;
  font-weight: 600;
  color: #2e9ca1;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.heading-section h2 {
  font-size: 32px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 15px;
  font-family: "Poppins", sans-serif;
}

.user-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  min-width: 80px;
  background-size: 100%;
}

.carousel-testimonial .item {
  padding: 30px 10px;
}

.quote {
  position: absolute;
  top: -23px;
  color: #2e9da1;
  font-size: 27px;
}

.name {
  margin-bottom: 0;
  line-height: 14px;
  font-size: 17px;
  font-weight: 500;
}

.position {
  color: #adadad;
  font-size: 14px;
}

.owl-nav button {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  outline: none;
  height: 25px;
}

.owl-nav button svg {
  width: 25px;
  height: 25px;
}

.owl-nav button.owl-prev {
  left: 25px;
}

.owl-nav button.owl-next {
  right: 25px;
}

.owl-nav button span {
  font-size: 45px;
}

.product-thumb .item img {
  height: 100px;
}

.product-name {
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 4px;
}

.product-price-discount {
  font-size: 22px;
  font-weight: 400;
  padding: 10px 0;
  clear: both;
}

.product-price-discount span.line-through {
  text-decoration: line-through;
  margin-left: 10px;
  font-size: 14px;
  vertical-align: middle;
  color: #a5a5a5;
}

.display-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.product-info {
  width: 100%;
}

.reviews-counter {
  font-size: 13px;
}

.reviews-counter span {
  vertical-align: -2px;
}

.rate {
  float: left;
  padding: 0 10px 0 0;
}

.rate:not(:checked)>input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked)>label {
  float: right;
  width: 15px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 21px;
  color: #ccc;
  margin-bottom: 0;
  line-height: 21px;
}

.rate:not(:checked)>label:before {
  content: "\2605";
}

.rate>input:checked~label {
  color: #ffc700;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
  color: #deb217;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
  color: #c59b08;
}

.product-dtl p {
  font-size: 14px;
  line-height: 24px;
  color: #7a7a7a;
}

.product-dtl .form-control {
  font-size: 15px;
}

.product-dtl label {
  line-height: 16px;
  font-size: 15px;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

.product-count {
  margin-top: 15px;
}

.product-count .qtyminus,
.product-count .qtyplus {
  width: 34px;
  height: 34px;
  background: #212529;
  text-align: center;
  font-size: 19px;
  line-height: 36px;
  color: #fff;
  cursor: pointer;
}

.product-count .qtyminus {
  border-radius: 3px 0 0 3px;
}

.product-count .qtyplus {
  border-radius: 0 3px 3px 0;
}

.product-count .qty {
  width: 60px;
  text-align: center;
}

.round-black-btn {
  border-radius: 4px;
  background: #212529;
  color: #fff;
  padding: 7px 45px;
  display: inline-block;
  margin-top: 20px;
  border: solid 2px #212529;
  transition: all 0.5s ease-in-out 0s;
}

.round-black-btn:hover,
.round-black-btn:focus {
  background: transparent;
  color: #212529;
  text-decoration: none;
}

.product-info-tabs {
  margin-top: 25px;
}

.product-info-tabs .nav-tabs {
  border-bottom: 2px solid #d8d8d8;
}

.product-info-tabs .nav-tabs .nav-item {
  margin-bottom: 0;
}

.product-info-tabs .nav-tabs .nav-link {
  border: none;
  border-bottom: 2px solid transparent;
  color: #323232;
}

.product-info-tabs .nav-tabs .nav-item .nav-link:hover {
  border: none;
}

.product-info-tabs .nav-tabs .nav-item.show .nav-link,
.product-info-tabs .nav-tabs .nav-link.active,
.product-info-tabs .nav-tabs .nav-link.active:hover {
  border: none;
  border-bottom: 2px solid #d8d8d8;
  font-weight: bold;
}

.product-info-tabs .tab-content .tab-pane {
  padding: 30px 20px;
  font-size: 15px;
  line-height: 24px;
  color: #7a7a7a;
}

.review-form .form-group {
  clear: both;
}

.mb-20 {
  margin-bottom: 20px;
}

.review-form .rate {
  float: none;
  display: inline-block;
}

.review-heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 6px;
  text-transform: uppercase;
  color: #000;
}

.review-form .form-control {
  font-size: 14px;
}

.review-form input.form-control {
  height: 40px;
}

.review-form textarea.form-control {
  resize: none;
}

.review-form .round-black-btn {
  text-transform: uppercase;
  cursor: pointer;
}



.ribbon {
  line-height: 0.9em;
  font-size: 1em;
  text-transform: uppercase;
  text-align: center;
  /* font-weight: lighter; */
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  /* letter-spacing: -1px; */
  display: block;
  width: 3rem;
  height: 3rem;
  background-color: #029ecb;
  color: white;
  margin: -1.3em -1.3em 0;
  float: right;
  padding-top: 1rem;
  position: relative;
  top: 0px;
  left: 0px;
  /* -webkit-filter: drop-shadow(0 0.5rem 0.3em rgba(0, 0, 0, 0.5)); */
  transform: translate3d(0, 0, 0);
}

.ribbon:after {
  content: "";
  width: 0;
  height: 0;
  border-right: 1.5rem solid transparent;
  border-left: 1.5rem solid transparent;
  border-top: 0.75rem solid #CCCCCC;
  position: absolute;
  top: 3rem;
  left: 0;
}

.ribbon.ribbon--blue {
  background-color: #029ecb;
}

.ribbon.ribbon--blue:after {
  border-top: 1.5rem solid #029ecb;
}

.img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.25rem;
}

.feature-overlay {
  position: absolute;
  top: -6px;
  left: -6px;
  padding: 1.25rem;
  font-size: 11px;
}

.outstock-overlay {
  position: absolute;
  /*top: 40%;*/
  margin-top: 130px;
  /* left: -20px; */
  /* padding: 1.25rem; */
  width: 100%;
  background: linear-gradient(90deg, #8bc34a 4%, #99d653 78%);
  text-align: center;
  /* font-size: 11px; */
}

.content {
  position: relative;
  /* width: 90%; */
  /* max-width: 400px; */
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  /* height: 99%;
width: 100%; */
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

/* .content:hover .content-overlay {
  opacity: 1;
} */

.content-image {
  width: 100%;
  height: 150px;
}

.content-details {
  position: absolute;
  text-align: center;
  /* padding-left: 1em;
padding-right: 1em; */
  /* width: 100%; */
  top: 33%;
  left: 50%;
  opacity: 0;
  /*font-size: 8px !important;*/
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}


.content-details2 {
  position: absolute;
  text-align: center;
  padding-left: 3em;
  padding-right: 3em;
  /*width: 100%; */
  top: 60%;
  left: 50%;
  opacity: 0;
  /*font-size: 8px;*/
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 33%;
  left: 50%;
  opacity: 1;
  /*background-color : black;*/
  color: white;
  border-radius: 50% !important;
  font-size: 10px;
}

.content:hover .content-details2 {
  top: 60%;
  left: 50%;
  opacity: 1;
  /*background-color : black;*/
  color: white;
  border-radius: 50%;
  font-size: 10px;
}

.content-details a {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.content-details a {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom {
  top: 80%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  column-gap: 1rem;
  row-gap: 1rem;
  /* background-color: #2196F3; */
  /* padding: 10px;  */
}

.card {
  background-color: white !important;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.limit-text {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-size: 12px;
  line-height: 14px;
  height: 28px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cartbtn {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
}