.dialog-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 9999;
  visibility: hidden; /* Hide the dialog box by default */
  opacity: 0; /* Set the opacity to 0 to start */
  transition: visibility 0.4s, opacity 0.5s ease-in-out; /* Add a transition effect */
}

.dialog-box.active {
  visibility: visible; /* Show the dialog box when it is active */
  opacity: 1; /* Set the opacity to 1 to make it fully visible */
}

.dialog-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
