body {
  scroll-behavior: smooth;
  padding-top: 70px;
}

/* @media screen and (min-width: 750px) {
  body {
    padding-left: 220px;
  }
} */

/*=============== SCROLL BAR ===============*/
::-webkit-scrollbar {
  width: 0.4rem;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(207, 207, 207);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bebebe;
}

.cart-button {
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.4s;
  margin-left: 0.4rem;
}

.cart-button:hover {
  color: #1194ff;
}

.my-shop {
  font-size: 1.4rem;
  cursor: pointer;
  transition: 0.4s;
  outline: none;
}

.my-shop:hover {
  color: rgb(255, 35, 35);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox
input[type=number] {
  -moz-appearance: textfield;
} */

.no_data img {
  height: 500px;
}

.no_data {
  display: flex;
  justify-content: center;
}

table {
  font-size: 12px;
}

.ql-editor {
  min-height: 30em;
}
.no-results {
  text-align: center;
  margin-top: 20px;
}

.no-results-text {
  font-size: 18px;
  color: #666;
  width: 100%;
}
