.loader {
  border: 5px solid #fff;
  border-radius: 50%;
  border-top: 4px solid #1a4d84 !important;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 0.4s linear infinite; /* Safari */
  animation: spin 0.4s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loaderdiv {
  display: flex;
  margin-top: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
